<template>
    <div class="GameType">
        <!--<MenuBar mode="top-only" @toggleMenu="openLeftMenu"/>-->
        <MenuBar v-if="!embedMode" @openGame="openGame" @toggleMenu="openLeftMenu"/>
        <transition name="slide">
            <MenuLeft @close="closeLeftMenu" v-show="showMenuLeft"></MenuLeft>
        </transition>



        <div class="box-live-room">
            <p>แนะนำ</p>
            <div class="wrap-room-chanel">
                <div class="box-chanel-live room-1"><div class="box-type-room">HOT</div></div>
                <div class="box-chanel-live room-2"></div>
                <div class="box-chanel-live room-3"></div>
                <div class="box-chanel-live room-4"></div>
                <div class="box-chanel-live room-5"></div>
                <div class="box-chanel-live room-6"><div class="box-type-room">HOT</div></div>
                <div class="box-chanel-live room-4"></div>
                <div class="box-chanel-live room-5"></div>
            </div>
        </div>


        <div class="box-live-stream">

            <!--box jackpot-->
            <div class="box-jackpots fadeInAndOut">
                <div class="box-txt-jackpot">
                    <span><div>JACKPOT</div><small>50,000</small></span>
                </div>
            </div>


            <!--box slide top-->
            <div class="box-float-top slide-right">
                <div class="box-content-float gradient-bg">
                    <div class="btn-close-st"><i class="fa fa-times"></i></div>
                    <span><img src="@/assets/images/profile2.jpg"></span>
                    <div class="txt-overflow"><strong>Jackson</strong> <span>ส่งของขวัญให้</span> <strong>Helen
                        S.</strong></div>
                    <div class="float-sticker"><img src="@/assets/images/sticker/pink-heart.png"></div>
                </div>
            </div>

            <div class="section-live">
                <!--<iframe width="100%" height="100%" src="https://www.youtube.com/embed/yCv0HpGo0fg" frameborder="0"-->
                <!--allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"-->
                <!--&lt;!&ndash;allowfullscreen></iframe>&ndash;&gt;-->
            </div>

            <div class="box-ui-list">
                <span><i class="far fa-user"></i> 20.3k</span>
                <span><i class="fas fa-star"></i> 2</span>
            </div>

            <div class="box-live-comment">
                <div class="box-greeting-msg slide-right">
                    <span>3 Lv</span>
                    <strong>Jackson</strong> มาแล้ว
                </div>

                <div class="box-float-sticker slide-right-short">
                    <div><img src="@/assets/images/profile3.jpg"></div>
                    <div class="box-txt-sticker">
                        <span>Chayapong Prommanee</span>
                        <span class="txt-small">ส่งของขวัญแล้ว 10 coin</span>
                        <div class="float-sticker"><img src="@/assets/images/sticker/pink-heart.png"></div>
                        <div class="float-number effect_dylan">x<strong class="counter-p"></strong></div>
                    </div>
                </div>

                <!--comment-->
                <div class="wrap-box-comment">
                    <div class="box-top-user-list box-video-live">
                        <div class="box-msg-inlive"><span>11</span> Minnie41 <strong>เข้าร่วมไลฟ์</strong></div>
                    </div>
                    <div class="box-top-user-list box-video-live">
                        <div class="box-msg-inlive"><span>45</span> Quinny_wonny <strong>เข้าร่วมไลฟ์</strong></div>
                    </div>
                    <div class="box-top-user-list box-video-live">
                        <div class="box-msg-inlive bg-follow"><b>+ Mamii ติดตาม</b></div>
                    </div>
                    <!--<div class="box-top-user-list box-video-live">-->
                    <!--<div class="box-msg-inlive bg-send-gift"><b><i class="fa fa-gift"></i> honey13 ส่งของขวัญ</b>-->
                    <!--</div>-->
                    <!--</div>-->
                    <div class="box-top-user-list box-video-live">
                        <div class="box-msg-inlive"><span class="bg-gradient">10</span> Gwon <strong>: hi hello</strong>
                        </div>
                    </div>
                    <div>
                        <div class="box-comments">
                            <i class="fa fa-comment"></i> OHHO : สวัสดีครับบบบ
                        </div>
                    </div>
                    <div>
                        <div class="box-comments">
                            <i class="fa fa-comment"></i> jayson23 : ทักทายครับ Hi Hi ..
                        </div>
                    </div>

                </div>

                <div class="box-icon-live">
                    <div class="box-icon-list">
                        <!--<span><i class="far fa-comment-dots"></i></span>-->
                        <!--<span><i class="far fa-share-square"></i></span>-->
                        <!--<span><i class="fas fa-crop-alt"></i></span>-->
                    </div>
                    <div>
                        <span><i class="far fa-share-square"></i></span>
                        <span class="bg-gradient-1"><i class="fas fa-gift"></i></span>
                        <span class="bg-gradient-3"><i class="fa fa-heart" style="color: #f01304;"></i></span>
                        <span><i class="fas fa-list-ul"></i></span>
                    </div>
                </div>
            </div>
        </div>

        <!--popup sticker large-->
        <div class="popup-center-sticker slide-top">
            <span><img src="@/assets/images/sticker/22.gif"></span>
        </div>

        <!--popup sticker -->
        <div class="popup-sticker">
            <div class="wrapper-box-sticker">
                <div class="box-icon-right"><i class="fa fa-box"></i></div>
                <nav>
                    <div class="nav nav-tabs nav-tabs-sticker" id="nav-tab" role="tablist">
                        <a class="nav-item nav-link active" id="nav-home-tab" data-toggle="tab" href="#nav-home"
                           role="tab" aria-controls="nav-home" aria-selected="true">กิจกรรม</a>
                        <a class="nav-item nav-link" id="nav-profile-tab" data-toggle="tab" href="#nav-profile"
                           role="tab" aria-controls="nav-profile" aria-selected="false">ฮอต</a>
                        <a class="nav-item nav-link" id="nav-contact-tab" data-toggle="tab" href="#nav-contact"
                           role="tab" aria-controls="nav-contact" aria-selected="false">กำหนดเอง</a>
                    </div>
                </nav>
                <div class="tab-content" id="nav-tabContent">
                    <div class="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                        <div class="wrap-overflow-sticker">
                            <div>
                                <div class="box-s-child active">
                                    <span>130xp</span>
                                    <img src="@/assets/images/sticker/1.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 1</p>
                                </div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/2.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                </div>
                            </div>
                            <div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/3.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 30</p>
                                </div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/4.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 199</p>
                                </div>
                            </div>
                            <div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/5.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 299</p>
                                </div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/6.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 1555</p>
                                </div>
                            </div>
                            <div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/1.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 1999</p>
                                </div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/1.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 2999</p>
                                </div>
                            </div>
                            <div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/1.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                </div>
                                <div class="box-s-child">
                                    <img src="@/assets/images/sticker/1.gif">
                                    <p><img src="@/assets/images/icon-coin.png"> 10</p>
                                </div>
                            </div>
                        </div>
                        <div class="box-sending">
                            <div><span><img src="@/assets/images/icon-coin.png" style="width: 10px;"> 0</span> <i
                                    class="fa fa-chevron-right"></i></div>
                            <div>
                                <div class="btn-group btn-group-sm" role="group"
                                     aria-label="Button group with nested dropdown">
                                    <div class="btn-group btn-group-sm" role="group">
                                        <button id="btnGroupDrop1" type="button"
                                                class="btn btn-outline-pink dropdown-toggle" data-toggle="dropdown"
                                                aria-haspopup="true" aria-expanded="false">
                                            1
                                        </button>
                                        <div class="dropdown-menu" aria-labelledby="btnGroupDrop1">
                                            <a class="dropdown-item" href="#">1</a>
                                            <a class="dropdown-item" href="#">10</a>
                                        </div>
                                    </div>
                                    <button type="button" class="btn btn-pink">ส่ง</button>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="tab-pane fade" id="nav-profile" role="tabpanel" aria-labelledby="nav-profile-tab">...
                    </div>
                    <div class="tab-pane fade" id="nav-contact" role="tabpanel" aria-labelledby="nav-contact-tab">...
                    </div>
                </div>
            </div>
        </div>

        <!--popup follow-->
        <div class="popup-follow">
            <div class="box-img-vj"></div>
            <div><strong>New vj Maewmeaw</strong></div>
            <small>ติดตาม VJ แล้วคุณจะไม่พลาดกับการไลฟ์สดอีกต่อไป</small>
            <div>
                <button class="btn btn-pink">ยืนยัน</button>
            </div>
        </div>
    </div>
</template>
<script>
    import MenuBar from '@/components/MenuBar'
    import MenuLeft from '@/components/MenuLeft'
    import LayoutMixin from '@/mixins/Layout'
    import GameMixin from '@/mixins/Game'

    export default {
        name: 'GameType',
        components: {
            MenuBar,
            MenuLeft,
        },
        mixins: [
            LayoutMixin,
            GameMixin,
        ],
        methods: {
            play(table) {
                this.selectGameTable(table)
            },
        },
    }
</script>
<style scoped src="@/assets/css/live.css"></style>
<style scoped>


    .box-live-stream {
        border-radius: 0px;
        background-size: cover;
        background-image: url(../assets/images/live-stream/3.jpg);
        background-position: center;
        position: fixed;
        height: 90vh;
        width: 100%;
    }

    .section-live {
        position: fixed;
        height: 83.5vh;
        width: 100%;
    }

    .app {
        overflow: hidden;
        position: fixed;
        width: 100%;
    }

    .level-orange {
        background-color: #f8bb37;
    }

    .level-blue {
        background-color: #2f9fff;
    }

    .box-comments {
        background-color: rgba(0, 0, 0, 0.5);
        width: auto;
        display: inline-block;
        border-radius: 5px;
        margin-bottom: 10px;
        font-size: 12px;
        color: #fff;
        padding: 5px;
    }

    .wrap-overflow-sticker {
        width: 100%;
        display: flex;
        flex-wrap: nowrap;
        overflow-y: hidden;
        overflow-x: auto;
    }

    .popup-sticker {
        display: none;
        width: 100%;
        background-color: rgba(0, 0, 0, 1);
        position: fixed;
        bottom: 0;
        /*border-radius: 20px 20px 0px 0px;*/
        padding: 5px 15px 10px 15px;
        font-family: "Athiti", sans-serif;
        border-bottom: 0px;
        box-shadow: rgba(14, 30, 37, 0.12) 0px 2px 4px 0px, rgba(14, 30, 37, 0.32) 0px 2px 16px 0px;
        transition: .35s ease-in-out;
        z-index: 9;
    }

    .nav-tabs-sticker .nav-link {
        color: #888;
        padding: 8px;
        font-size: 13px;
    }

    .nav-tabs-sticker {
        border-bottom: 0px;
    }

    .nav-tabs-sticker .nav-link.active, .nav-tabs-sticker .nav-item.show .nav-link {
        color: #fff;
        background: none;
        font-weight: 600;
        border: none !important;
    }

    .wrap-overflow-sticker div {
        margin-right: 1px;
    }

    .wrap-overflow-sticker div > .box-s-child {
        width: 83px;
        position: relative;
        color: #fff;
        text-align: center;
        padding: 10px 10px 5px 10px;
    }

    .wrap-overflow-sticker span {
        position: absolute;
        top: 0;
        right: 0;
        background-color: #e96fdb;
        border-radius: 0px 0px 0px 5px;
        padding: 2px 3px;
        font-size: 11px;
        line-height: 11px;
        font-weight: 600;
    }

    .wrap-overflow-sticker div > .box-s-child > img {
        max-height: 46px;
        margin-bottom: 3px;
    }

    .wrap-overflow-sticker div > .box-s-child > p {
        margin: 0px;
        padding: 0px;
        font-size: 12px;
    }

    .wrap-overflow-sticker div > .box-s-child > p > img {
        width: 10px;
        display: inline-block;
        margin-top: -2px;
    }

    .wrap-overflow-sticker div > .box-s-child.active {
        border: solid 1px #e96fdb;
        border-radius: 3px;
    }

    .box-sending {
        display: flex;
        color: #eee;
        margin-top: 10px;
        border-top: solid 1px #222;
        padding-top: 5px;
    }

    .box-sending div:first-child {
        flex: 3;
        font-size: 12px;
    }

    .box-sending div:first-child img {
        margin-top: -2px;
    }

    .btn-sm, .btn-group-sm > .btn {
        line-height: 1;
    }

    .btn-outline-pink {
        border: solid 1px #e96fdb;
        color: #fff;
    }

    .btn-pink {
        background-color: #e96fdb;
        color: #fff;
    }

    .box-greeting-msg {
        margin-bottom: 10px;
        display: inline-block;
        padding: 0px 10px 0px 0px;
        color: #fff;
        background-color: rgba(0, 0, 0, 0.8);
        border-radius: 5px;
        font-size: 14px;
        font-family: "Athiti", sans-serif;
        border: solid 1px #e96fdb;
        position: relative;
    }

    .box-greeting-msg span {
        display: inline-block;
        border-radius: 5px 0px 0px 5px;
        background-color: #e96fdb;
        padding: 0px 8px;
        margin: 0px 10px 0px -2px;
    }

    .box-greeting-msg strong {
        color: #e96fdb;
    }

    .room-1 {
        background-image: url(../assets/images/live-stream/woman.jpg);
    }

    .room-2 {
        background-image: url(../assets/images/live-stream/2.jpg);
    }

    .room-3 {
        background-image: url(../assets/images/live-stream/3.jpg);
    }

    .room-4 {
        background-image: url(../assets/images/live-stream/4.jpg);
    }

    .room-5 {
        background-image: url(../assets/images/live-stream/5.jpg);
    }

    .room-6 {
        background-image: url(../assets/images/live-stream/6.jpg);
    }


    .fadeInAndOut {
        -webkit-animation: fadeinout 3s linear forwards;
        animation: fadeinout 3s linear forwards;
        opacity: 0;
        animation-iteration-count: infinite;
    }

    @keyframes fadeinout {
        50% { opacity: 1; }
    }

    @keyframes fadeinout {
        100% { opacity: 1; }
    }


</style>